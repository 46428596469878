
















import {Vue, Component, Prop} from "vue-property-decorator";

@Component
export default class Banner extends Vue {
  @Prop({
    // type: Function as PropType<(imageName: string) => void>
  })
  loadImageCallBack;

  onImageLoaded(imageName) {
    this.loadImageCallBack(imageName);
  }
}
