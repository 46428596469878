
























import {Vue, Component} from "vue-property-decorator";

@Component
export default class Orange extends Vue {
  loadedImages: string[] = []
  loadedImagesTotal = 4; 

  get imagesLoaded() {
    return this.loadedImages.length === this.loadedImagesTotal;
  }

  addLoadedImage(image) {
    this.loadedImages.push(image);
  }
}
