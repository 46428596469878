













































import OpeningHours from "@/store/openingHours";
import {Vue,Component} from "vue-property-decorator";

@Component({
  computed: {
    isContactPage() {
      return this.$route.name != "Contact";
    }
  }
})
export default class Footer extends Vue {
  openingHours = new OpeningHours();
  allDays = this.openingHours.allDays;
}
