














import {Vue, Component} from "vue-property-decorator";
import OpeningHours from "../store/openingHours";

@Component({
  computed: {
    getOpeningHours() {
      const now: Date = new Date();
      const openingHours = new OpeningHours();
      const today = now.getDay();
      let startTime = openingHours.getStartTimeShop(today);
      let endTime = openingHours.getEndTimeShop(today);
      if (startTime.length < 5) {
        startTime = startTime + 0;
      }
      if (endTime.length < 5) {
        endTime = endTime + 0;
      }
      return `${startTime} tot ${endTime}`;
    }
  }
})
export default class TodaysHours extends Vue {
  currentDate!: Date;
}
