import { render, staticRenderFns } from "./Gebo.vue?vue&type=template&id=7a443364&scoped=true&"
import script from "./Gebo.vue?vue&type=script&lang=ts&"
export * from "./Gebo.vue?vue&type=script&lang=ts&"
import style0 from "./Gebo.vue?vue&type=style&index=0&id=7a443364&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a443364",
  null
  
)

export default component.exports