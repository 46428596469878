const assortimentImages = [
  "Frites-Bord",
  "Dinos-Burgers",
  "Snacks-Bord",
  "Dinos-Fried-Chicken-1",
  "Dinos-Fried-Chicken-2",
  "Kroketten-Bord",
  "Ijs-Milkshakes",
  "Frisdranken"
]

export default assortimentImages;