






























import {Vue, Component} from "vue-property-decorator";
import {mapGetters} from "vuex";

interface RouterLink {
  name: string;
  link: string;
}

@Component({
  computed: {
    ...mapGetters({
      sideBarState: "getStateSideBar"
    })
  }
})
export default class NavBar extends Vue {
  sideBarState;

  routerLinks: RouterLink[] = [
    {
      name: "Home",
      link: "/"
    },
    {
      name: "Assortiment",
      link: "/assortiment"
    },
    {
      name: "Bestellen",
      link: "/bezorgen"
    },
    {
      name: "Contact",
      link: "/contact"
    },
    {
      name: "Gebo Gelato",
      link: "/gebo"
    }
  ]

  toggleSideBar() {
    if (this.sideBarState) {
      this.$store.dispatch("toggleSideBar", false);
    } else {
      this.$store.dispatch("toggleSideBar", true);
    }
  }
}
