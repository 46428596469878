import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sideBarToggled: false
  },
  mutations: {
    toggleSideBar(state, boolean: boolean): void {
      state.sideBarToggled = boolean;
    }
  },
  actions: {
    toggleSideBar({commit}, boolean: boolean): void {
      commit("toggleSideBar", boolean);
    }
  },
  getters: {
    getStateSideBar(state): boolean {
      return state.sideBarToggled;
    }
  }
})
