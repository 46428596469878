





















import {Vue, Component} from "vue-property-decorator";
import assortimentImages from "@/store/assortiment";
import RingLoader from 'vue-spinner/src/ClipLoader.vue'

@Component({
  components: {
    RingLoader
  }
})
export default class Assortiment extends Vue {
  assortimentImages = assortimentImages;

  get allImagesLoaded() {
    return this.loadedImages.length === this.assortimentImages.length;
  }

  loadedImages: string[] = [];

  loaded(image: string) {
    this.loadedImages.push(image);
  }
}
