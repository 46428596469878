




































import {Vue, Component} from "vue-property-decorator";
import RingLoader from 'vue-spinner/src/ClipLoader.vue';

@Component({
  components: {
    RingLoader
  }
})
export default class Gebo extends Vue {
  geboItems = [
    "Iedere dag geopend van 12:00 tot 20:00",
    "Ambachtelijk bereid schepijs",
    "Geleverd door familiebedrijf Gebo Gelato uit Oud-Beijerland (sinds 1950)",
    "24 topsmaken",
    "De winkel is open van april t/m september",
    "5 topsmaken het hele jaar te bestellen via Dino's bezorgservice"
  ]
  loadedImages: string[] = [];
  loadedImagesLength = this.geboItems.length + 3;

  get allImagesLoaded() {
    return this.loadedImages.length === this.loadedImagesLength;
  }

  loaded(image: string) {
    this.loadedImages.push(image);
  }
}
