












































import {Vue, Component} from "vue-property-decorator";
import RingLoader from 'vue-spinner/src/ClipLoader.vue';

@Component({
  components: {
    RingLoader
  }
})
export default class DeliveryView extends Vue {
  loadedImages: string[] = [];
  loadedImagesLength = 1;

  get allImagesLoaded() {
    return this.loadedImages.length === this.loadedImagesLength;
  }

  loaded(image: string) {
    this.loadedImages.push(image);
  }
}
