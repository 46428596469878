export type DayType = {
  name: string;
  shop: {
    start: number[];
    end: number[];
  };
  delivery: {
    start: number[];
    end: number[];
  };
}

export default class OpeningHours  {
  constructor (
  public monday = {
    name: "maandag",
    shop: {
      start: [11, 30],
      end: [20, 0]
    },
    delivery: {
      start: [17, 0],
      end: [20, 0]
    }
  },
  public tuesday = {
    name: "dinsdag",
    shop: {
      start: [11, 30],
      end: [20, 0]
    },
    delivery: {
      start: [17, 0],
      end: [20, 0]
    }
  },
  public wednesday = {
    name: "woensdag",
    shop: {
      start: [11, 30],
      end: [20, 0]
    },
    delivery: {
      start: [17, 0],
      end: [20, 0]
    }
  },
  public thursday = {
    name: "donderdag",
    shop: {
      start: [11, 30],
      end: [20, 0]
    },
    delivery: {
      start: [17, 0],
      end: [20, 0]
    }
  },
  public friday = {
    name: "vrijdag",
    shop: {
      start: [11, 30],
      end: [21, 0]
    },
    delivery: {
      start: [12, 0],
      end: [0, 0]
    }
  },
  public saturday = {
    name: "zaterdag",
    shop: {
      start: [11, 30],
      end: [21, 0]
    },
    delivery: {
      start: [12, 0],
      end: [0, 0]
    }
  },
  public sunday = {
    name: "zondag",
    shop: {
      start: [12, 0],
      end: [20, 0]
    },
    delivery: {
      start: [16, 0],
      end: [20, 0]
    }
  }
  ) {}

  public get allDays(): DayType[] {
    return [this.monday, this.tuesday, this.wednesday, this.thursday, this.friday, this.saturday, this.sunday];
  }

  public static openingHours = new OpeningHours();

  private days = {
    1: this.monday,
    2: this.tuesday,
    3: this.wednesday,
    4: this.thursday,
    5: this.friday,
    6: this.saturday,
    7: this.sunday
  }

  public getStartTimeDelivery(day: number) {
    const dayObject: DayType = this.day(day);
    const startHour = dayObject.delivery.start[0];
    const startMinute = dayObject.delivery.start[1];
    let startMinuteString = startMinute.toString();
    if (startMinute.toString().length < 2) {
      startMinuteString += 0;
    }
    return `${startHour}:${startMinuteString}`;
  }

  public getEndTimeDelivery(day: number) {
    const dayObject: DayType = this.day(day);
    const endHour = dayObject.delivery.end[0];
    const endMinute = dayObject.delivery.end[1];
    let endMinuteString = endMinute.toString();
    if (endMinute.toString.length < 2) {
      endMinuteString += 0;
    }
    return `${endHour}:${endMinuteString}`;
  }

  public getStartTimeShop(day: number) {
    const dayObject: DayType = this.day(day);
    const startHour = dayObject.shop.start[0];
    const startMinute = dayObject.shop.start[1];
    let startMinuteString = startMinute.toString();
    if (startMinute.toString().length < 2) {
      startMinuteString += 0;
    }
    return `${startHour}:${startMinuteString}`
  }

  public getEndTimeShop(day: number) {
    const dayObject: DayType = this.day(day);
    const endHour = dayObject.shop.end[0];
    const endMinute = dayObject.shop.end[1];
    let endMinuteString = endMinute.toString();
    if (endMinute.toString().length < 2) {
      endMinuteString += 0;
    }
    return `${endHour}:${endMinuteString}`
  }

  private day(day: number) {
    return this.days[day];
  }
}