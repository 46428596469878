










































import OpeningHours from "@/store/openingHours";
import {Vue, Component} from "vue-property-decorator";

@Component
export default class Contact extends Vue {
  openingHours = new OpeningHours();
  allDays = this.openingHours.allDays;

}
