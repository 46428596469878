import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import DeliveryView from "../views/DeliveryView.vue"
import Contact from "../views/Contact.vue";
import Assortiment from "../views/Assortiment.vue";
import Gebo from "../views/Gebo.vue";
import Orange from "../views/Orange.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/bezorgen",
    name: "Delivery",
    component: DeliveryView
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/assortiment",
    name: "Assortiment",
    component: Assortiment
  },
  {
    path: "/gebo",
    name: "Gebo",
    component: Gebo
  },
  {
    path: "/oranje",
    name: "Oranje",
    component: Orange
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return {x: 0, y: 0};
  }
})

export default router
