


















import {Vue, Component} from "vue-property-decorator";
import Banner from "@/components/Banner.vue";
import TodaysHours from "@/components/TodaysHours.vue";
import Delivery from "@/components/Delivery.vue";
import GeboHome from "@/components/GeboHome.vue";
import RingLoader from 'vue-spinner/src/ClipLoader.vue';

@Component({
  components: {
    Banner,
    TodaysHours,
    Delivery,
    GeboHome,
    RingLoader
  }
})
export default class Home extends Vue {
  loadedImages: string[] = [];
  imagesLength = 6;

  get allImagesLoaded() {
    return this.loadedImages.length === this.imagesLength;
  }

  loadImageCallBack(imageName) {
    this.loadedImages.push(imageName);
  }
}
