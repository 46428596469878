












import {Vue, Component, Prop} from "vue-property-decorator";
import {PropType} from "vue";

@Component
export default class GeboHome extends Vue {
  @Prop({
    type: Function as PropType<(imageName: string) => void>
  })
  loadImageCallBack;

  onImageLoaded(imageName) {
    this.loadImageCallBack(imageName);
  }
}
